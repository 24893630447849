import { WizardPage } from '../../components/wizard/types';
import {
    DelayReasonModel,
    EOTRegistrationReasonsEnumDesc,
} from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';

const eotReasons: FieldModel<DelayReasonModel> = {
    component: 'checkboxGroup',
    label: 'Provide reasons for requesting an extension of time to lodge this application:',
    name: 'eotReasons',
    maxWidth: '22rem',
    contentKey: ['eotReasons'],
    componentProps: {
        includeAll: false,
        includeNone: false,
        checkOptions: [
            {
                id: 'eotReasons-ActOrOmissionOfBoard',
                label: EOTRegistrationReasonsEnumDesc['ActOrOmissionOfBoard'],
                name: 'eotReasons-ActOrOmissionOfBoard',
                value: 'ActOrOmissionOfBoard',
            },
            {
                id: 'eotReasons-IrresponsibleEvent',
                label: EOTRegistrationReasonsEnumDesc['IrresponsibleEvent'],
                name: 'eotReasons-IrresponsibleEvent',
                value: 'IrresponsibleEvent',
            },
            {
                id: 'eotReasons-FailureOfKeyPersonnel',
                label: EOTRegistrationReasonsEnumDesc['FailureOfKeyPersonnel'],
                name: 'eoteasons-FailureOfKeyPersonnel',
                value: 'FailureOfKeyPersonnel',
            },
            {
                id: 'eotReasons-ActOrOmissionOfOther',
                label: EOTRegistrationReasonsEnumDesc['ActOrOmissionOfOther'],
                name: 'eotReasons-ActOrOmissionOfOther',
                value: 'ActOrOmissionOfOther',
            },
        ],
    },
};

const eotCircumstancesDescription: FieldModel<DelayReasonModel> = {
    component: 'textAreaInput',
    label: 'Please describe circumstances below:',
    name: 'eotCircumstancesDescription',
    contentKey: ['eotCircumstancesDescription1', 'eotCircumstancesDescription2'],
    componentProps: {
        maxCharacters: 1000,
    },
};

const eotSupportingEvidence: FieldModel<DelayReasonModel> = {
    component: 'fileUpload',
    label: 'Supporting evidence',
    name: 'eotSupportingEvidence',
    contentKey: ['eotSupportingEvidence'],
    componentProps: {
        minSize: 1,
        maxSize: 31457280,
        multiple: true,
        maxFiles: 5,
        maxFilesText: 'five',
    },
};

const delayReason: WizardPage<DelayReasonModel> = {
    apiEndpoint: '/delay-reason',
    name: 'delayReason',
    title: 'Reason for delay',
    fields: {
        eotReasons,
        eotCircumstancesDescription,
        eotSupportingEvidence,
    },
    initialPageValues: {
        eotReasons: [],
        eotCircumstancesDescription: undefined,
        eotSupportingEvidence: undefined,
    },
};

export default delayReason;
