import { ValidationSchema, Tuple } from '../../types';
import { DelayReasonModel, SupportingFileItemModel } from '../../api/models';
import {
    textMandatory,
    notEmptyStringArray,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import * as validatorRules from '../validatorRules.json';
import { NotEmpty } from '../../utils';

const eotReasonsValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEmptyStringArray, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const eotCircumstancesDescriptionSoftValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const minLength = (len: number) =>
    (value: string | undefined) => {
        return !NotEmpty(value) || value === undefined || value.length >= len;
    };
export const maxLength = (len: number) => (value: string | undefined) => !NotEmpty(value) || value === undefined || value.length <= len;

export const notEmptySupportingEvidenceArray = (value: Partial<SupportingFileItemModel>[]) => value && value.length > 0;

export const eotSupportingEvidenceSoftValidator: Tuple<(value: any) => boolean, string>[] = [
    [notEmptySupportingEvidenceArray, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

export const eotCircumstancesDescriptionHardValidator: Tuple<(_value: any) => boolean, string>[] = [
    [minLength(50), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['50'])],
    [maxLength(1000), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['1000'])],
];

export const delayReason: ValidationSchema<Partial<DelayReasonModel>> = (_values: Partial<DelayReasonModel>) => {
    return {
        eotCircumstancesDescription: eotCircumstancesDescriptionHardValidator,
    };
};

export const delayReasonSoft: ValidationSchema<Partial<DelayReasonModel>> = (_values: Partial<DelayReasonModel>) => {
    return {
        ...delayReason,
        eotReasons: eotReasonsValidator,
        eotCircumstancesDescription: eotCircumstancesDescriptionSoftValidator,
        eotSupportingEvidence: eotSupportingEvidenceSoftValidator,
    };
};
