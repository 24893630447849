
export type ApplicantType = 'TaxAgent' | 'Consultant' | 'RnDEntity';
export type ApplicantTypeDesc = {
    [K in ApplicantType]: string;
};
export const ApplicantTypeEnumDesc: ApplicantTypeDesc = {
    TaxAgent: `A tax agent authorised to act on behalf of the R&D entity`,
    Consultant: `A consultant authorised to act on behalf of the R&D entity`,
    RnDEntity: `The R&D entity itself (e.g. company owner, position holder, employee)`,
};
export type OrganisationIncorporatedType = 'AustLaw' | 'ForeignLaw' | 'ForeignLawDoubleTax' | 'NotIncorporated';
export type OrganisationIncorporatedTypeDesc = {
    [K in OrganisationIncorporatedType]: string;
};
export const OrganisationIncorporatedTypeEnumDesc: OrganisationIncorporatedTypeDesc = {
    AustLaw: `Under an Australian law`,
    ForeignLaw: `Under foreign law that is an Australian resident for tax purposes`,
    ForeignLawDoubleTax: `Under foreign law and double tax agreement`,
    NotIncorporated: `Not incorporated`,
};
export type OrganisationMecType = 'HeadMecGroup' | 'NotPartMecGroup' | 'SubsidiaryMecGroup';
export type OrganisationMecTypeDesc = {
    [K in OrganisationMecType]: string;
};
export const OrganisationMecTypeEnumDesc: OrganisationMecTypeDesc = {
    HeadMecGroup: `Yes`,
    NotPartMecGroup: `No, the company is not part of a consolidated or multiple entry consolidated group`,
    SubsidiaryMecGroup: `No, the company is a subsidiary of a consolidated or multiple entry consolidated group`,
};
export type AOFCompanyType = 'Individual' | 'HeadCompany';
export type AOFCompanyTypeDesc = {
    [K in AOFCompanyType]: string;
};
export const AOFCompanyTypeEnumDesc: AOFCompanyTypeDesc = {
    Individual: `Individual entity (non-consolidated)`,
    HeadCompany: `Head company of consolidated or multiple entry consolidated (MEC) group`,
};
export type ApplicationWhoConductedActivitiesType = 'HeadCompanyOnly' | 'SubsidiaryMembersOnly' | 'BothHeadCompanyAndSubsidiaryMembers';
export type ApplicationWhoConductedActivitiesTypeDesc = {
    [K in ApplicationWhoConductedActivitiesType]: string;
};
export const ApplicationWhoConductedActivitiesTypeEnumDesc: ApplicationWhoConductedActivitiesTypeDesc = {
    HeadCompanyOnly: `The head company only`,
    SubsidiaryMembersOnly: `Subsidiary members only`,
    BothHeadCompanyAndSubsidiaryMembers: `Both the head company and subsidiary members`,
};
export type YesNo = 'No' | 'Yes';
export type YesNoDesc = {
    [K in YesNo]: string;
};
export const YesNoEnumDesc: YesNoDesc = {
    No: `No`,
    Yes: `Yes`,
};
export type ProjectsAndActivitiesType = 'Projects' | 'CoreActivities' | 'SupportingActivities' | 'AofProjects';
export type ProjectsAndActivitiesTypeDesc = {
    [K in ProjectsAndActivitiesType]: string;
};
export const ProjectsAndActivitiesTypeEnumDesc: ProjectsAndActivitiesTypeDesc = {
    Projects: `Projects`,
    CoreActivities: `CoreActivities`,
    SupportingActivities: `SupportingActivities`,
    AofProjects: `AofProjects`,
};
export type CRCRSPActivityType = 'No' | 'YesCRC' | 'YesRSP' | 'YesRSPLevy';
export type CRCRSPActivityTypeDesc = {
    [K in CRCRSPActivityType]: string;
};
export const CRCRSPActivityTypeEnumDesc: CRCRSPActivityTypeDesc = {
    No: `No`,
    YesCRC: `YesCRC`,
    YesRSP: `YesRSP`,
    YesRSPLevy: `YesRSPLevy`,
};
export type Title = 'Mr' | 'Mrs' | 'Dr' | 'Sir' | 'Ms' | 'Miss' | 'Other';
export type TitleDesc = {
    [K in Title]: string;
};
export const TitleEnumDesc: TitleDesc = {
    Mr: `Mr`,
    Mrs: `Mrs`,
    Dr: `Dr`,
    Sir: `Sir`,
    Ms: `Ms`,
    Miss: `Miss`,
    Other: `Other`,
};
export type YesNoPreferNotToAnswer = 'No' | 'Yes' | 'PreferNotToAnswer';
export type YesNoPreferNotToAnswerDesc = {
    [K in YesNoPreferNotToAnswer]: string;
};
export const YesNoPreferNotToAnswerEnumDesc: YesNoPreferNotToAnswerDesc = {
    No: `No`,
    Yes: `Yes`,
    PreferNotToAnswer: `Prefer not to answer`,
};
export type YesNoUnverified = 'Y' | 'N' | 'U';
export type YesNoUnverifiedDesc = {
    [K in YesNoUnverified]: string;
};
export const YesNoUnverifiedEnumDesc: YesNoUnverifiedDesc = {
    Y: `Yes`,
    N: `No`,
    U: `Unverified`,
};
export type HasControllingRspType = 'YT' | 'YG' | 'N';
export type HasControllingRspTypeDesc = {
    [K in HasControllingRspType]: string;
};
export const HasControllingRspTypeEnumDesc: HasControllingRspTypeDesc = {
    YT: `Yes - Tertiary education institution`,
    YG: `Yes - Government research organisation`,
    N: `No`,
};
export type FormPageStatus = 'NotStarted' | 'InProgress' | 'Completed' | 'Hidden';
export type FormPageStatusDesc = {
    [K in FormPageStatus]: string;
};
export const FormPageStatusEnumDesc: FormPageStatusDesc = {
    NotStarted: `Not started`,
    InProgress: `In progress`,
    Completed: `Completed`,
    Hidden: `Hidden`,
};
export type CompanyConsolidated = 'IsSubsidiary' | 'NotSubsidiary' | 'Yes';
export type CompanyConsolidatedDesc = {
    [K in CompanyConsolidated]: string;
};
export const CompanyConsolidatedEnumDesc: CompanyConsolidatedDesc = {
    IsSubsidiary: `Is subsidiary`,
    NotSubsidiary: `Not subsidiary`,
    Yes: `Yes`,
};
export type AustralianStates = 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA';
export type AustralianStatesDesc = {
    [K in AustralianStates]: string;
};
export const AustralianStatesEnumDesc: AustralianStatesDesc = {
    ACT: `ACT`,
    NSW: `NSW`,
    NT: `NT`,
    QLD: `QLD`,
    SA: `SA`,
    TAS: `TAS`,
    VIC: `VIC`,
    WA: `WA`,
};
export type ErrorStatusCode = 'OK' | 'BadRequest' | 'Unauthorized' | 'NotFound' | 'Conflict' | 'UnprocessableEntity' | 'InternalServerError';
export type ErrorStatusCodeDesc = {
    [K in ErrorStatusCode]: string;
};
export const ErrorStatusCodeEnumDesc: ErrorStatusCodeDesc = {
    OK: `OK`,
    BadRequest: `Bad request`,
    Unauthorized: `Unauthorized`,
    NotFound: `Not found`,
    Conflict: `Conflict`,
    UnprocessableEntity: `Unprocessable Entity`,
    InternalServerError: `Internal server error`,
};
export type RegistrationFormType = 'RnDActivities' | 'Overseas' | 'RSP' | 'AdvancedFindings' | 'EOTRegistration' | 'Withdrawal' | 'Variation' | 'RequestToVary' | 'AdvanceOverseasFinding' | 'ATOMessage' | 'RenewRSP' | 'VariationRSP';
export type RegistrationFormTypeDesc = {
    [K in RegistrationFormType]: string;
};
export const RegistrationFormTypeEnumDesc: RegistrationFormTypeDesc = {
    RnDActivities: `R&D tax incentive application`,
    Overseas: `R&D Overseas registration`,
    RSP: `RSP registration application`,
    AdvancedFindings: `Advanced Findings`,
    EOTRegistration: `Time Extension request`,
    Withdrawal: `Withdrawal request`,
    Variation: `Variation`,
    RequestToVary: `Request to vary`,
    AdvanceOverseasFinding: `Advance overseas finding`,
    ATOMessage: `ATO message`,
    RenewRSP: `RSP renewal application`,
    VariationRSP: `RSP registration variation`,
};
export type RegistrationFormStatus = 'Draft' | 'SubmittedPending' | 'SubmittedReceived' | 'Completed' | 'Refused' | 'Approved' | 'Cancelled' | 'UnderReview' | 'AwaitingResponse' | 'Registered' | 'InReview' | 'NotStarted' | 'AwaitingInformation' | 'Withdrawn' | 'CancelRequested' | 'ForAction' | 'Expired' | 'PartiallyApproved' | 'Superseded' | 'Revoked' | 'Inactive' | 'RegisteredUnderExamination' | 'InReviewUnderExamination' | 'PendingExpertInput' | 'Discontinued' | 'CompletedEligible' | 'CompletedPartiallyEligible' | 'CompletedIneligible';
export type RegistrationFormStatusDesc = {
    [K in RegistrationFormStatus]: string;
};
export const RegistrationFormStatusEnumDesc: RegistrationFormStatusDesc = {
    Draft: `Draft`,
    SubmittedPending: `Submitted`,
    SubmittedReceived: `Submitted`,
    Completed: `Completed`,
    Refused: `Refused`,
    Approved: `Approved`,
    Cancelled: `Cancelled`,
    UnderReview: `Under review`,
    AwaitingResponse: `Awaiting response`,
    Registered: `Registered`,
    InReview: `In review`,
    NotStarted: `Not started`,
    AwaitingInformation: `Awaiting information`,
    Withdrawn: `Withdrawn`,
    CancelRequested: `Cancellation requested`,
    ForAction: `For action`,
    Expired: `Expired`,
    PartiallyApproved: `Partially approved`,
    Superseded: `Superseded`,
    Revoked: `Revoked`,
    Inactive: `Inactive`,
    RegisteredUnderExamination: `Registered - under examination`,
    InReviewUnderExamination: `In review - under examination`,
    PendingExpertInput: `Pending expert input`,
    Discontinued: `Discontinued`,
    CompletedEligible: `Completed – eligible`,
    CompletedPartiallyEligible: `Completed – partially eligible`,
    CompletedIneligible: `Completed – ineligible`,
};
export type IdentifierType = 'Abn' | 'Acn' | 'Arbn';
export type IdentifierTypeDesc = {
    [K in IdentifierType]: string;
};
export const IdentifierTypeEnumDesc: IdentifierTypeDesc = {
    Abn: `Entity is identified by ABN`,
    Acn: `Entity is identified by ACN`,
    Arbn: `Entity is identified by ARBN`,
};
export type AsicNumberType = 'Acn' | 'Arbn' | 'Arsn' | 'Number';
export type AsicNumberTypeDesc = {
    [K in AsicNumberType]: string;
};
export const AsicNumberTypeEnumDesc: AsicNumberTypeDesc = {
    Acn: `ACN – meaning Australian Company Number`,
    Arbn: `ARBN - meaning Australian Registered Body Number`,
    Arsn: `ARSN - meaning Australian Registered Scheme Number`,
    Number: `Number – used for all other types of identifiers not mentioned above`,
};
export type ApplicationActivityType = 'WithAdvanced' | 'LevyPaidRSP' | 'CRC' | 'RSP' | 'OtherResearch' | 'OtherAgreement' | 'None';
export type ApplicationActivityTypeDesc = {
    [K in ApplicationActivityType]: string;
};
export const ApplicationActivityTypeEnumDesc: ApplicationActivityTypeDesc = {
    WithAdvanced: `Activities with an advance or overseas finding`,
    LevyPaidRSP: `Expenditure paid via a levy to a Research Service Provider`,
    CRC: `Activities conducted by a Cooperative Research Centre`,
    RSP: `Activities conducted by a Research Service Provider`,
    OtherResearch: `Activities conducted by another research organisation`,
    OtherAgreement: `Activities conducted under another collaborative agreement`,
    None: `None of the above`,
};
export type RelatedOrganisationType = 'CRC' | 'RSP' | 'RSPLevy' | 'Public' | 'Private' | 'Collaborative' | 'None';
export type RelatedOrganisationTypeDesc = {
    [K in RelatedOrganisationType]: string;
};
export const RelatedOrganisationTypeEnumDesc: RelatedOrganisationTypeDesc = {
    CRC: `contracted to a Cooperative Research Centre`,
    RSP: `contracted to a Research Service Provider`,
    RSPLevy: `contracted to a levy collecting Research Service Provider`,
    Public: `contracted to another type of publicly funded research organisation`,
    Private: `contracted to another type of private research organisation`,
    Collaborative: `other collaborative agreement`,
    None: `No`,
};
export type CoreActivityOutcomeType = 'NoInformation' | 'NoSolution' | 'CannotAdapt' | 'Other' | 'None';
export type CoreActivityOutcomeTypeDesc = {
    [K in CoreActivityOutcomeType]: string;
};
export const CoreActivityOutcomeTypeEnumDesc: CoreActivityOutcomeTypeDesc = {
    NoInformation: `There was no applicable information in scientific, technical, or professional literature or patents`,
    NoSolution: `Experts in the field provided advice that there wasn't a solution that could be applied`,
    CannotAdapt: `There wasn’t a way to adapt solutions from other companies in, and out of, Australia`,
    Other: `Other`,
    None: `The company did not look into existing knowledge`,
};
export type CoreActivityEvidenceAvailableType = 'Existing' | 'Generate' | 'Design' | 'Evaluation' | 'Other' | 'None';
export type CoreActivityEvidenceAvailableTypeDesc = {
    [K in CoreActivityEvidenceAvailableType]: string;
};
export const CoreActivityEvidenceAvailableTypeEnumDesc: CoreActivityEvidenceAvailableTypeDesc = {
    Existing: `Evidence of searches or enquiries you made to find current knowledge`,
    Generate: `Evidence to show that you could only determine the outcome of the core activity by conducting experiments as part of a systematic progression of work`,
    Design: `Evidence of your hypothesis and design of your experiments`,
    Evaluation: `Documented results and evaluation of your experiments`,
    Other: `Other`,
    None: `The company did not keep records`,
};
export type AOFCoreActivityEvidenceAvailableType = 'Design' | 'Evaluation' | 'Revisions' | 'Other' | 'None';
export type AOFCoreActivityEvidenceAvailableTypeDesc = {
    [K in AOFCoreActivityEvidenceAvailableType]: string;
};
export const AOFCoreActivityEvidenceAvailableTypeEnumDesc: AOFCoreActivityEvidenceAvailableTypeDesc = {
    Design: `Evidence of your hypothesis and design of your experiments`,
    Evaluation: `Documented results and evaluation of your experiments`,
    Revisions: `Evidence of revisions to your experiment in response to previous experimental results`,
    Other: `Other`,
    None: `The company did not keep records`,
};
export type AccountingPeriodType = 'Substituted' | 'Transitional';
export type AccountingPeriodTypeDesc = {
    [K in AccountingPeriodType]: string;
};
export const AccountingPeriodTypeEnumDesc: AccountingPeriodTypeDesc = {
    Substituted: `Substituted accounting period (SAP)`,
    Transitional: `Transitional accounting period`,
};
export type EOTRegistrationReasons = 'ActOrOmissionOfBoard' | 'IrresponsibleEvent' | 'FailureOfKeyPersonnel' | 'ActOrOmissionOfOther';
export type EOTRegistrationReasonsDesc = {
    [K in EOTRegistrationReasons]: string;
};
export const EOTRegistrationReasonsEnumDesc: EOTRegistrationReasonsDesc = {
    ActOrOmissionOfBoard: `An act or omission of the Board (i.e. Innovation and Science Australia, departmental officers)`,
    IrresponsibleEvent: `An event for which no one is responsible (fire, floods, cyclone, illness/death of key personnel, system failure)`,
    FailureOfKeyPersonnel: `A failure of the company’s key personnel to perform necessary functions (e.g. malicious intent, sabotage, court proceedings)`,
    ActOrOmissionOfOther: `An act or omission of another person (a person not related to the company)`,
};
export type EOTRegistrationDeclarations = 'ReadUnderstood' | 'IncludedReasons' | 'IncludedEvidence' | 'AuthorisedInfo';
export type EOTRegistrationDeclarationsDesc = {
    [K in EOTRegistrationDeclarations]: string;
};
export const EOTRegistrationDeclarationsEnumDesc: EOTRegistrationDeclarationsDesc = {
    ReadUnderstood: `I have read and understood the ‘Background and Requirements’ for the ‘Further period of time to lodge an application for registration of R&D Activities’.`,
    IncludedReasons: `The reason/s for the further period of time are included in this request.`,
    IncludedEvidence: `Evidence to support the reason/s for the further period of time is/are included with this request.`,
    AuthorisedInfo: `I declare all the information provided in this form (and the attachments) is true and correct and has been authorised by the nominated contact or an authorised officer of the applicant.`,
};
export type FileType = 'ApplicationPdf' | 'Attachment' | 'ApplicationJson';
export type FileTypeDesc = {
    [K in FileType]: string;
};
export const FileTypeEnumDesc: FileTypeDesc = {
    ApplicationPdf: `Application PDF`,
    Attachment: `Attachment`,
    ApplicationJson: `Application Json`,
};
export type WithdrawReasons = 'NoLongerWantToApply' | 'ResubmitNewApplication' | 'Ineligible' | 'PreferNotToSay' | 'Other';
export type WithdrawReasonsDesc = {
    [K in WithdrawReasons]: string;
};
export const WithdrawReasonsEnumDesc: WithdrawReasonsDesc = {
    NoLongerWantToApply: `I no longer want to apply for the RDTI program`,
    ResubmitNewApplication: `I need to resubmit a new application form with amended details`,
    Ineligible: `I have been advised that I am ineligible by RDTI staff members`,
    PreferNotToSay: `Prefer not to say`,
    Other: `Other`,
};
export type ApplicationSubmittedHeading = 'ApplicationSubmitted' | 'WithdrawalRequestSubmitted' | 'WithdrawalRequested' | 'RequestToVarySubmitted';
export type ApplicationSubmittedHeadingDesc = {
    [K in ApplicationSubmittedHeading]: string;
};
export const ApplicationSubmittedHeadingEnumDesc: ApplicationSubmittedHeadingDesc = {
    ApplicationSubmitted: `Application submitted`,
    WithdrawalRequestSubmitted: `Withdrawal request submitted`,
    WithdrawalRequested: `Withdrawal requested`,
    RequestToVarySubmitted: `Request to vary submitted`,
};
export type VariationRequestDecision = 'Approve' | 'Cancel' | 'PartiallyApprove' | 'Refuse';
export type VariationRequestDecisionDesc = {
    [K in VariationRequestDecision]: string;
};
export const VariationRequestDecisionEnumDesc: VariationRequestDecisionDesc = {
    Approve: `Approve`,
    Cancel: `Cancel`,
    PartiallyApprove: `PartiallyApprove`,
    Refuse: `Refuse`,
};
export type Lateness = 'NotDue' | 'Soon' | 'Today' | 'Overdue';
export type LatenessDesc = {
    [K in Lateness]: string;
};
export const LatenessEnumDesc: LatenessDesc = {
    NotDue: ``,
    Soon: `Due soon!`,
    Today: `Due today!`,
    Overdue: `OVERDUE!`,
};
export type UserActionScope = 'Application' | 'Delegation';
export type UserActionScopeDesc = {
    [K in UserActionScope]: string;
};
export const UserActionScopeEnumDesc: UserActionScopeDesc = {
    Application: `Application`,
    Delegation: `Delegation`,
};
export type OrganisationRole = 'TaxProfessional' | 'Subsidiary' | 'Other';
export type OrganisationRoleDesc = {
    [K in OrganisationRole]: string;
};
export const OrganisationRoleEnumDesc: OrganisationRoleDesc = {
    TaxProfessional: `Tax Professional`,
    Subsidiary: `Subsidiary`,
    Other: `Other`,
};
export type ProfileFavouriteType = 'BusinessAuthorisationOrganisation';
export type ProfileFavouriteTypeDesc = {
    [K in ProfileFavouriteType]: string;
};
export const ProfileFavouriteTypeEnumDesc: ProfileFavouriteTypeDesc = {
    BusinessAuthorisationOrganisation: `Business Authorisation Organisation`,
};
export type RevisionStatus = 'Draft' | 'Submitted';
export type RevisionStatusDesc = {
    [K in RevisionStatus]: string;
};
export const RevisionStatusEnumDesc: RevisionStatusDesc = {
    Draft: `Draft`,
    Submitted: `Submitted`,
};
export type ActivityType = 'CoreActivity' | 'SupportingActivity';
export type ActivityTypeDesc = {
    [K in ActivityType]: string;
};
export const ActivityTypeEnumDesc: ActivityTypeDesc = {
    CoreActivity: `Core Activity`,
    SupportingActivity: `Supporting Activity`,
};
export type MessageType = 'Error' | 'Warning' | 'Information';
export type MessageTypeDesc = {
    [K in MessageType]: string;
};
export const MessageTypeEnumDesc: MessageTypeDesc = {
    Error: `Error`,
    Warning: `Warning`,
    Information: `Information`,
};
export type ResearchOrganisationType = 'CRC' | 'RSP' | 'LevyCollectingRSP';
export type ResearchOrganisationTypeDesc = {
    [K in ResearchOrganisationType]: string;
};
export const ResearchOrganisationTypeEnumDesc: ResearchOrganisationTypeDesc = {
    CRC: `Cooperative Research Centre`,
    RSP: `Research Service Provider`,
    LevyCollectingRSP: `Levy collecting Research Service Provider`,
};
export type PersistentIdentifierType = 'Portal' | 'Radias';
export type PersistentIdentifierTypeDesc = {
    [K in PersistentIdentifierType]: string;
};
export const PersistentIdentifierTypeEnumDesc: PersistentIdentifierTypeDesc = {
    Portal: `Portal`,
    Radias: `Radias`,
};
export type DelegationStatus = 'Active' | 'Inactive' | 'Expired' | 'Revoked' | 'NotStarted';
export type DelegationStatusDesc = {
    [K in DelegationStatus]: string;
};
export const DelegationStatusEnumDesc: DelegationStatusDesc = {
    Active: `Active`,
    Inactive: `Inactive`,
    Expired: `Expired`,
    Revoked: `Revoked`,
    NotStarted: `Not started`,
};
export type RequestToVaryOptionType = 'RegistrationType' | 'RnDEntityDetails' | 'ContactDetails' | 'ApplicationInclusions' | 'Employees' | 'Finance' | 'ProjectsAndActivities';
export type RequestToVaryOptionTypeDesc = {
    [K in RequestToVaryOptionType]: string;
};
export const RequestToVaryOptionTypeEnumDesc: RequestToVaryOptionTypeDesc = {
    RegistrationType: `Registration type`,
    RnDEntityDetails: `R&D entity details`,
    ContactDetails: `Contact details`,
    ApplicationInclusions: `Application inclusions`,
    Employees: `Employees`,
    Finance: `Finance`,
    ProjectsAndActivities: `Projects and activities`,
};
export type VariationStatusAction = 'Refused' | 'RequestApproved' | 'RequestPartiallyApproved' | 'Unknown';
export type VariationStatusActionDesc = {
    [K in VariationStatusAction]: string;
};
export const VariationStatusActionEnumDesc: VariationStatusActionDesc = {
    Refused: `Refused`,
    RequestApproved: `Request Approved`,
    RequestPartiallyApproved: `Request Partially Approved`,
    Unknown: `Unknown`,
};
export type ApplicationAction = 'BeginApplication' | 'BeginNewApplication' | 'SelectApplication' | 'ViewApplication' | 'ViewRequest' | 'DownloadDraftPDF' | 'DeleteApplication' | 'ApplicationSubmitted' | 'RequestExtension' | 'DeleteWithEotApplication' | 'DeleteRequestExtension' | 'CancelRequestExtension' | 'Withdraw' | 'WithdrawalRequested' | 'WithdrawalRequestSubmitted' | 'DeleteWithdrawalRequest' | 'RequestToVary' | 'CancelRequestToVary' | 'DeleteRequestToVary' | 'RequestToVarySubmitted' | 'RevertToDraft' | 'CancelVariation';
export type ApplicationActionDesc = {
    [K in ApplicationAction]: string;
};
export const ApplicationActionEnumDesc: ApplicationActionDesc = {
    BeginApplication: `Begin application`,
    BeginNewApplication: `Begin new application`,
    SelectApplication: `Resume`,
    ViewApplication: `View application`,
    ViewRequest: `View request`,
    DownloadDraftPDF: `Download draft PDF`,
    DeleteApplication: `Delete application`,
    ApplicationSubmitted: `Application submitted`,
    RequestExtension: `Request extension`,
    DeleteWithEotApplication: `Delete application`,
    DeleteRequestExtension: `Delete request`,
    CancelRequestExtension: `Cancel request`,
    Withdraw: `Withdraw`,
    WithdrawalRequested: `Withdrawal requested`,
    WithdrawalRequestSubmitted: `Withdrawal request submitted`,
    DeleteWithdrawalRequest: `Delete request`,
    RequestToVary: `Request to vary`,
    CancelRequestToVary: `Cancel request`,
    DeleteRequestToVary: `Delete request`,
    RequestToVarySubmitted: `Request to vary submitted`,
    RevertToDraft: `Revert my draft`,
    CancelVariation: `Cancel request`,
};
export type VerifiedAsicStatus = 'VerifiedRegistered' | 'VerifiedNotRegistered' | 'Unverified';
export type VerifiedAsicStatusDesc = {
    [K in VerifiedAsicStatus]: string;
};
export const VerifiedAsicStatusEnumDesc: VerifiedAsicStatusDesc = {
    VerifiedRegistered: `Verified registered`,
    VerifiedNotRegistered: `Verified not registered`,
    Unverified: `Unverified`,
};
export type AOFActivitiesPerformedByOptions = 'OnlyTheRnDCompany' | 'RnDCompanyAndOthers' | 'EitherASubsidiary' | 'OnlyOthers';
export type AOFActivitiesPerformedByOptionsDesc = {
    [K in AOFActivitiesPerformedByOptions]: string;
};
export const AOFActivitiesPerformedByOptionsEnumDesc: AOFActivitiesPerformedByOptionsDesc = {
    OnlyTheRnDCompany: `Only the R&D Company`,
    RnDCompanyAndOthers: `The R&D Company and Others (including RSPs and CRCs)`,
    EitherASubsidiary: `Either a subsidiary; by more than one company within the group; or by Others (including RSPs and CRCs) working with at least one other company within the group`,
    OnlyOthers: `Only Others (including RSPs and CRCs)`,
};
export type AOFSupportingActivityRelationshipOption = 'RelatedToCore' | 'UnderTaken';
export type AOFSupportingActivityRelationshipOptionDesc = {
    [K in AOFSupportingActivityRelationshipOption]: string;
};
export const AOFSupportingActivityRelationshipOptionEnumDesc: AOFSupportingActivityRelationshipOptionDesc = {
    RelatedToCore: `Directly related to the claimed core R&D activity (as above), and`,
    UnderTaken: `(If required) undertaken for the dominant purpose of supporting core R&D activities.`,
};
export type AOFSupportingActivityIncludeOption = 'Obtain' | 'Assess';
export type AOFSupportingActivityIncludeOptionDesc = {
    [K in AOFSupportingActivityIncludeOption]: string;
};
export const AOFSupportingActivityIncludeOptionEnumDesc: AOFSupportingActivityIncludeOptionDesc = {
    Obtain: `Obtain a finding`,
    Assess: `Be assessed to support eligibility of other activities for finding`,
};
export type RegisteredIncomeYearOption = 'One' | 'Two' | 'Three' | 'Four' | 'Five' | 'Six';
export type RegisteredIncomeYearOptionDesc = {
    [K in RegisteredIncomeYearOption]: string;
};
export const RegisteredIncomeYearOptionEnumDesc: RegisteredIncomeYearOptionDesc = {
    One: `One`,
    Two: `Two`,
    Three: `Three`,
    Four: `Four`,
    Five: `Five`,
    Six: `Six`,
};
export type ActivityPurpose = 'ObtainFinding' | 'BeAssessedForFinding';
export type ActivityPurposeDesc = {
    [K in ActivityPurpose]: string;
};
export const ActivityPurposeEnumDesc: ActivityPurposeDesc = {
    ObtainFinding: `Obtain a finding`,
    BeAssessedForFinding: `Be assessed to support eligibility of other activities for finding`,
};
export type ReasonsConductedOverseas = 'FacilityNotInAus' | 'PopulationNotInAus' | 'RelatingQuarantine' | 'FeatureNotInAus';
export type ReasonsConductedOverseasDesc = {
    [K in ReasonsConductedOverseas]: string;
};
export const ReasonsConductedOverseasEnumDesc: ReasonsConductedOverseasDesc = {
    FacilityNotInAus: `it requires access to a facility, expertise or equipment not available in Australia`,
    PopulationNotInAus: `it requires access to a population (of living things) not available in Australia`,
    RelatingQuarantine: `if conducted in Australia it would contravene the Biosecurity Act 2015 or a law relating to quarantine`,
    FeatureNotInAus: `it requires access to a geographical or geological feature not available in Australia`,
};
export type AOFApplicantType = 'TaxAgent' | 'TaxRnDConsultant' | 'RSP' | 'CRC' | 'CRCEntity' | 'Other';
export type AOFApplicantTypeDesc = {
    [K in AOFApplicantType]: string;
};
export const AOFApplicantTypeEnumDesc: AOFApplicantTypeDesc = {
    TaxAgent: `Registered Tax Agent acting with the Company's authorisation`,
    TaxRnDConsultant: `Tax or R&D Consultant`,
    RSP: `Research Service Provider (RSP)`,
    CRC: `Cooperative Research Centre (where CRC is one entity)`,
    CRCEntity: `One of the entities that makes up a Cooperative Research Centre`,
    Other: `Other`,
};
export type AOFPageNames = 'CompanyDetails' | 'ContactDetails' | 'SupportingDocumentation' | 'ProjectsAndActivities' | 'CoreActivities';
export type AOFPageNamesDesc = {
    [K in AOFPageNames]: string;
};
export const AOFPageNamesEnumDesc: AOFPageNamesDesc = {
    CompanyDetails: `Company details`,
    ContactDetails: `Contact details`,
    SupportingDocumentation: `Supporting documentation`,
    ProjectsAndActivities: `Projects and activities`,
    CoreActivities: `Core R&D activity`,
};
export type RspCategory = 'PubliclyControlledOrganisation' | 'Other' | 'LevyCollectingOrganisation';
export type RspCategoryDesc = {
    [K in RspCategory]: string;
};
export const RspCategoryEnumDesc: RspCategoryDesc = {
    PubliclyControlledOrganisation: `Publicly controlled organisation`,
    Other: `Other R&D organisation`,
    LevyCollectingOrganisation: `Levy collecting organisation`,
};
export type SubsidiaryMemberActivityType = 'ConductActivity' | 'ProvideServices' | 'UseParticularTechnology' | 'None';
export type SubsidiaryMemberActivityTypeDesc = {
    [K in SubsidiaryMemberActivityType]: string;
};
export const SubsidiaryMemberActivityTypeEnumDesc: SubsidiaryMemberActivityTypeDesc = {
    ConductActivity: `conducted, or will conduct, an activity included in this application; or`,
    ProvideServices: `had, or will have, services provided in relation to an activity included in this application; or`,
    UseParticularTechnology: `used, or will use, particular technology in relation to an activity included in this application;`,
    None: `None of the above`,
};
export type RoleWithinCompany = 'AuthorizedPerson' | 'Employee';
export type RoleWithinCompanyDesc = {
    [K in RoleWithinCompany]: string;
};
export const RoleWithinCompanyEnumDesc: RoleWithinCompanyDesc = {
    AuthorizedPerson: `A Director, Office Holder, or other person authorised to act on behalf of the company`,
    Employee: `An employee of the company`,
};
export type ActivityConductedByType = 'ConsolidatedMECGroup' | 'Subsidiary';
export type ActivityConductedByTypeDesc = {
    [K in ActivityConductedByType]: string;
};
export const ActivityConductedByTypeEnumDesc: ActivityConductedByTypeDesc = {
    ConsolidatedMECGroup: `The activity will be conducted by the applicant as the head entity of a consolidated group or MEC group`,
    Subsidiary: `The activity will be conducted by a subsidiary`,
};
export type DeclarantRndEntityRelationship = 'AuthorizedPerson' | 'ActingOnBehalfPerson';
export type DeclarantRndEntityRelationshipDesc = {
    [K in DeclarantRndEntityRelationship]: string;
};
export const DeclarantRndEntityRelationshipEnumDesc: DeclarantRndEntityRelationshipDesc = {
    AuthorizedPerson: `A Director, Office Holder, or other person authorised to act on behalf of the company`,
    ActingOnBehalfPerson: `Someone acting on behalf of the company`,
};
