import { WizardPage } from '../../components/wizard/types';
import {
    EOTRegistrationDeclarationsEnumDesc,
    DeclarationSubmissionModel,
} from '../../api/models';
import { FieldModel } from '../../components/form/pageForm/types';
import { titleSelectInputProps } from '../CommonComponentProps';

const eotDeclarations: FieldModel<DeclarationSubmissionModel> = {
    component: 'checkboxGroup',
    label: 'I declare that:',
    name: 'eotDeclarations',
    maxWidth: '22rem',
    contentKey: ['eotDeclarations'],
    componentProps: {
        includeAll: false,
        includeNone: false,
        checkOptions: [
            {
                id: 'eotDeclarations-ReadUnderstood',
                label: EOTRegistrationDeclarationsEnumDesc['ReadUnderstood'],
                name: 'eotDeclarations-ReadUnderstood',
                value: 'ReadUnderstood',
            },
            {
                id: 'eotDeclarations-IncludedReasons',
                label: EOTRegistrationDeclarationsEnumDesc['IncludedReasons'],
                name: 'eotDeclarations-IncludedReasons',
                value: 'IncludedReasons',
            },
            {
                id: 'eotDeclarations-IncludedEvidence',
                label: EOTRegistrationDeclarationsEnumDesc['IncludedEvidence'],
                name: 'eotDeclarations-IncludedEvidence',
                value: 'IncludedEvidence',
            },
            {
                id: 'eotDeclarations-AuthorisedInfo',
                label: EOTRegistrationDeclarationsEnumDesc['AuthorisedInfo'],
                name: 'eotDeclarations-AuthorisedInfo',
                value: 'AuthorisedInfo',
            },
        ],
    },
};

const declarantHeader: FieldModel<DeclarationSubmissionModel> = {
    component: 'labelSubHeading',
    label: 'Declarant details',
    name: 'organisationCotactDetailsLabel',
    contentKey: ['eotDeclarantHeader'],
};

const eotDeclarantTitle = titleSelectInputProps('eotDeclarantTitle', 'Title (optional)', true);
const eotDeclarantTitleOther: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'eotDeclarantTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};

const eotDeclarantFirstName: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'eotDeclarantFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const eotDeclarantLastName: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'eotDeclarantLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const eotDeclarantPhone: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Phone',
    name: 'eotDeclarantPhone',
    maxWidth: '16.25rem',
    contentKey: ['eotDeclarantPhoneNumber'],
};
const eotDeclarantEmail: FieldModel<DeclarationSubmissionModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'eotDeclarantEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};

const declarationSubmission: WizardPage<DeclarationSubmissionModel> = {
    apiEndpoint: '/declaration-submission',
    name: 'declarationSubmission',
    title: 'Declaration and submit',
    fields: {
        eotDeclarations,
        declarantHeader,
        eotDeclarantTitle,
        eotDeclarantTitleOther,
        eotDeclarantFirstName,
        eotDeclarantLastName,
        eotDeclarantEmail,
        eotDeclarantPhone,
    },
    initialPageValues: {
        eotDeclarations: [],
        eotDeclarantTitle: undefined,
        eotDeclarantTitleOther: '',
        eotDeclarantFirstName: undefined,
        eotDeclarantLastName: undefined,
        eotDeclarantEmail: undefined,
        eotDeclarantPhone: undefined,
    },
    showSubmitConfirmationModal: true,
};

export default declarationSubmission;
