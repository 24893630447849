import { Tuple, ValidationSchema } from '../../types';
import { textMandatory } from '../validationFunctions';
import { AOFFinanceModel } from '../../api/models';
import { maxCurrencyLength, greaterThanOrEqualZero, mandatoryCurrency } from '../validationFunctions/commonCurrencyInput';
import * as validatorRules from '../validatorRules.json';
import { isEmpty } from 'lodash';

export const incomeYearCheck = (value: string | undefined) => {
    const stringValue = `${value}`;
    const incomeYearArray = stringValue.split('/');
    if (incomeYearArray.length !== 2 || stringValue.length !== 7) {
        return false;
    }
    const startIncomeYear = incomeYearArray[0];
    const endIncomeYear = incomeYearArray[1];

    if (parseInt(startIncomeYear) < 2006 || parseInt(startIncomeYear) > 2049 || parseInt(endIncomeYear) < 7 || parseInt(endIncomeYear) > 50) {
        return false;
    }

    if (parseInt(startIncomeYear.slice(-2)) + 1 !== parseInt(endIncomeYear)) {
        return false;
    }
    return true;
};

export const incomeYearValidator: Tuple<(value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
    [incomeYearCheck, validatorRules.BR214_Valid_Income_Year.FieldMessage],
];

export const finance: ValidationSchema<Partial<AOFFinanceModel>> = () => {
    return {
        incomeYear: incomeYearValidator,
        applicationOrganisationTaxableIncome: [
            ...mandatoryCurrency(),
            maxCurrencyLength(12),
        ],
        applicationOrganisationAggregatedTurnover: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
            maxCurrencyLength(12),
        ],
        applicationOrganisationExportSales: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
            maxCurrencyLength(12),
        ],
        applicationOrganisationRnDExpenditure: [
            ...mandatoryCurrency(),
            greaterThanOrEqualZero(),
            maxCurrencyLength(12),
        ],
    };
};
