import { ValidationSchema, Tuple } from '../../types';
import { DeclarationSubmissionModel } from '../../api/models';
import {
    textMandatory,
    minLength,
    maxLength,
    nameAtLeastOneChar,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameNoConsecutivePunctuationSpace,
    phoneNumberFormat,
    emailValidation,
    alphanumericBasic,
    maxLengthIgnoreBracketsSpaceAndPlus,
    minLengthIgnoreBracketsSpaceAndPlus,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationString } from '../util';

const mandatoryValidator: Tuple<(_value: any) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const mandatoryWithCondition = (condition?: () => boolean): [(value: any | undefined) => boolean, string][] => {
    if (!condition || condition()) {
        return mandatoryValidator;
    }
    return [];
};

// all 4 checkboxes checked
const isValidEotDeclarations = (value: any) => {
    return value.length === 4;
};

const eotDeclarationsValidator: Tuple<(_value: any) => boolean, string>[] = [
    [isValidEotDeclarations, validatorRules.BR97_EotDeclaration_Validation.FieldMessage],
];

const titleOtherValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(30), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['30'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const namePersonValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNo3ConsecutiveChars, interpolateValidationString(validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameAtLeastOneChar, interpolateValidationString(validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNoConsecutivePunctuationSpace, interpolateValidationString(validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const emailValidator: Tuple<(value: any | undefined) => boolean, string>[] = [
    [maxLength(320), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['320'])],
    [emailValidation, interpolateValidationString(validatorRules.BR10_Email_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const phoneValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLengthIgnoreBracketsSpaceAndPlus(10), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['10'])],
    [maxLengthIgnoreBracketsSpaceAndPlus(18), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['18'])],
    [phoneNumberFormat, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

// soft validation
export const declarationSubmissionSoft: ValidationSchema<Partial<DeclarationSubmissionModel>> = (values: Partial<DeclarationSubmissionModel>) => {
    return {
        eotDeclarations: eotDeclarationsValidator,
        eotDeclarantTitleOther: mandatoryWithCondition(() => values.eotDeclarantTitle === 'Other'),
        eotDeclarantFirstName: mandatoryValidator,
        eotDeclarantLastName: mandatoryValidator,
        eotDeclarantEmail: mandatoryValidator,
        eotDeclarantPhone: mandatoryValidator,
    };
};

// hard validation
export const declarationSubmission: ValidationSchema<Partial<DeclarationSubmissionModel>> = (_values: Partial<DeclarationSubmissionModel>) => {
    return {
        eotDeclarantTitleOther: titleOtherValidator,
        eotDeclarantFirstName: namePersonValidator,
        eotDeclarantLastName: namePersonValidator,
        eotDeclarantEmail: emailValidator,
        eotDeclarantPhone: phoneValidator,
    };
};
