import spected from 'spected';
import { ValidationSchema } from '../types';
import { forEach, isString, reduce, pickBy, isEmpty, keys, isNaN, isArray, get, Dictionary } from 'lodash';
import { visible } from '../displayFunctions';
import { PageFormProps } from '../components/form/pageForm/types';
import { ValidationModel, EntityDetailsModel } from '../api/models';
import { ErrorMessage } from '../components/models';
import * as validatorRules from './validatorRules.json';
import { FormikErrors } from '../formik';
import { IsMandatoryCheckPass } from './validationFunctions';

export interface SecondInterpolationValue {
    token: SecondInterpolationTokens;
    value: string;
}

export type SecondInterpolationTokens = 'field' | 'component' | 'label' | 'value';

export const mandatoryMessage = validatorRules.BR1_Mandatory_Field.FieldMessage;

export const mandatoryCurrencyMessage = validatorRules.BR34_02_Currency.FieldMessage;

export const mandatoryString = (condition = () => true): [(value: string | undefined | null) => boolean, string][] => {
    if (!condition || condition()) {
        return [[value => IsMandatoryCheckPass(value), validatorRules.BR1_Mandatory_Field.FieldMessage]];
    }
    return [];
};

const isNumeric = (value: string) => {
    return value && value !== '' && !isNaN(Number(value));
};

const appendPrefix = (object: any, value: string) => {
    return Object.keys(object).reduce((appenedResult, child) => ({ ...appenedResult, [`${value}.${child}`]: object[child] }), {});
};

const getErrorsFromValidationResult = <T extends any>(validationResult: any, props: PageFormProps<T>, values: T): FormikErrors<T> => {
    const FIRST_ERROR = 0;

    const allErrors = Object.keys(validationResult).reduce((errors: { [id: string]: {}; }, field) => {

        const fieldVisible = visible(values, props.name, props.formName, field);

        const result = get(validationResult, field);
        if (result === true || !fieldVisible) {
            return errors;
        }

        const firstElement = result[FIRST_ERROR];
        if (isString(firstElement)) {
            return { ...errors, [field]: firstElement };
        }

        const resultKeys = keys(result);
        if (resultKeys.length > 0 && !isNumeric(resultKeys[0])) {
            const newErrors = pickBy(result, r => r !== null && r !== true);
            if (!isEmpty(newErrors)) {
                const nestedObjects = pickBy(newErrors, r => !(isString(r)) && !isArray(r));
                Object.keys(pickBy(newErrors, r => isString(r) || isArray(r))).forEach(value => {
                    errors[`${field}.${value}`] = result[value][FIRST_ERROR];
                });

                if (!isEmpty(nestedObjects)) {
                    let nestedErrors =  Object.keys(nestedObjects).reduce((errorsObj, fieldName) => {
                        let childErrors = getErrorsFromValidationResult(result[fieldName], props, get(values, fieldName));
                        if (!isEmpty(childErrors)) {
                            childErrors = appendPrefix(childErrors, fieldName);
                        }
                        return { ...errorsObj, ...childErrors };
                    }, {});
                    if (nestedErrors !== undefined) {
                        nestedErrors = appendPrefix(nestedErrors, field);
                        return { ...errors, ...nestedErrors };
                    }
                }
            }
            return errors;
        }

        const arrayErrors = reduce(Object.keys(result), (aErrors, _value, key) => {
            const arrayResult = result[key];

            if (arrayResult === true) {
                return aErrors;
            }

            return reduce(Object.keys(arrayResult), (fieldError, value, _key) => {
                const fieldArrayResult = arrayResult[value];
                if (fieldArrayResult === true) {
                    return fieldError;
                }

                const fieldKey = `${field}.${key}.${value}`;

                if (!isArray(fieldArrayResult)) {
                    const objResult = getErrorsFromValidationResult(fieldArrayResult, props, get(values, fieldKey));
                    if (objResult !== undefined) {
                        const objErrors = appendPrefix(objResult, fieldKey);
                        return{ ...fieldError, ...objErrors };
                    }
                    return{ ...fieldError };
                }

                return { ...fieldError, [fieldKey]: fieldArrayResult[FIRST_ERROR] };
            }, aErrors);

        }, errors);

        return arrayErrors;
    }, {});
    return allErrors as FormikErrors<T>;
};

export const getValidationResult = <T extends any>(getValidationSchema: ValidationSchema<T>, values: any) => {
    const spec = getValidationSchema(values) as any;
    return spected(spec, values);
};

export const validateWizardPage = <T extends any>(getValidationSchema: ValidationSchema<T>) => {
    return (values: T, props: PageFormProps<T>) => {
        const validationResult = getValidationResult(getValidationSchema, values);
        return getErrorsFromValidationResult(validationResult, props, values);
    };
};

export const interpolateValidationString = (validationMessage: string, replacementTokensToValue: string[][], replacementNumbersToValue: string[]): string => {
    let validationMessageInterpolated: string = validationMessage;

    forEach(replacementTokensToValue, value => {
        validationMessageInterpolated = validationMessageInterpolated.replace(`{{${value[0]}}}`, value[1]);
    });

    forEach(replacementNumbersToValue, (_, index) => {
        validationMessageInterpolated = validationMessageInterpolated.replace(`{${index}}`, replacementNumbersToValue[index]);
    });

    return validationMessageInterpolated;
};

export const interpolateValidationStringSecondInterpolationTokens = (
    validationMessage: string,
    replacementTokensToValue: SecondInterpolationValue[]): string => {
    const replacementTokensToValueArray: string[][] = replacementTokensToValue.map(el => [el.token, el.value]);

    return interpolateValidationString(validationMessage, replacementTokensToValueArray, []);
};

export const extractHardValidations = (validations: Partial<ValidationModel>[] | undefined): Partial<ErrorMessage>[] | undefined => {
    const errors: Partial<ErrorMessage>[] = [];

    if (validations) {
        forEach(validations, validation => {
            if (validation.hardError) {
                errors.push({ code: validation.propertyName, message: validation.errorMessage });
            }
        });
    }
    return errors.length > 0 ? errors : undefined;
};

export const executeConditionally = (execute: (value: any | undefined) => boolean, condition?: () => boolean): ((value: any | undefined) => boolean) =>
    value => condition ?
        condition()
            ? execute(value)
            : true
        : execute(value);

export const hasIncorporationDate = (entityDetailsModel: Partial<EntityDetailsModel>) => {
    const exists = (entityDetailsModel &&
        hasAcnOrArbn(entityDetailsModel) &&
        entityDetailsModel.registrationDate !== undefined);
    return exists;
};

export const hasAcnOrArbn = (entityDetailsModel: Partial<EntityDetailsModel>) => {
    const acnExists = entityDetailsModel.acn !== undefined && entityDetailsModel.acn !== '';
    const arbnExists = entityDetailsModel.arbn !== undefined && entityDetailsModel.arbn !== '';

    const exists = (entityDetailsModel &&
        (acnExists || arbnExists));
    return exists;
};

export const hasDuplicates = (array: any[]): boolean => {
    return (new Set(array)).size !== array.length;
};
