import { ValidationSchema, Tuple } from '../../types';
import { AddressModel, RnDEntityContactDetailsModel } from '../../../src/api/models';
import {
    textMandatory,
    minLength,
    maxLength,
    nameBasicFormat,
    nameNo3ConsecutiveChars,
    nameAtLeastOneChar,
    nameNoConsecutivePunctuationSpace,
    phoneNumberFormat,
    emailValidation,
    alphanumericBasic,
    postcodeOptional,
    maxLengthIgnoreBracketsSpaceAndPlus,
    minLengthIgnoreBracketsSpaceAndPlus,
} from '../validationFunctions';
import { interpolateValidationRule } from '../validatorMessages';
import * as validatorRules from '../validatorRules.json';
import { interpolateValidationString, mandatoryString } from '../util';
import { NotEmpty } from '../../utils';
import { find } from 'lodash';
import * as Enum from './../../api/models/Enums';

const mandatoryValidator: Tuple<(_value: any | undefined) => boolean, string>[] = [
    [textMandatory, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const titleOtherValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(30), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['30'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const namePersonValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [nameBasicFormat, interpolateValidationString(validatorRules.BR14_Name_Person_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNo3ConsecutiveChars, interpolateValidationString(validatorRules.BR14_Name_Person_No_3_Consecutive_Characters.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameAtLeastOneChar, interpolateValidationString(validatorRules.BR14_Name_Person_At_Least_One_Character.FieldMessage, [['PropertyName', '{{label}}']], [])],
    [nameNoConsecutivePunctuationSpace, interpolateValidationString(validatorRules.BR14_Name_Person_No_Consecutive_Punctuation_Or_Spaces.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const positionValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
    [alphanumericBasic, validatorRules.BR11_Alphanumeric_Basic_Format.FieldMessage],
];

const phoneValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLengthIgnoreBracketsSpaceAndPlus(10), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['10'])],
    [maxLengthIgnoreBracketsSpaceAndPlus(18), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['18'])],
    [phoneNumberFormat, interpolateValidationString(validatorRules.BR13_Phone_Number_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

const addressLine1Validator: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

const addressLine2Validator: Tuple<(value: any) => boolean, string>[] = [
    [maxLength(100), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['100'])],
];

const suburbValidator: Tuple<(value: any) => boolean, string>[] = [
    [minLength(2), interpolateValidationRule(validatorRules.BR2_Minimum_Field_Length, [], ['2'])],
    [maxLength(50), interpolateValidationRule(validatorRules.BR3_Maximum_Field_Length, [], ['50'])],
];

const isInEnum = (value: string | undefined) => {
    if (!NotEmpty(value)) {
        return true;
    }
    if (Enum.AustralianStatesEnumDesc && find(Enum.AustralianStatesEnumDesc, c => c === value)) {
        return true;
    }
    if (Enum.YesNoEnumDesc && find(Enum.YesNoEnumDesc, c => c === value)) {
        return true;
    }
    return false;
};

const stateValidator: Tuple<(value: any) => boolean, string>[] = [
    [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const isAddressManualValidator: Tuple<(value: any) => boolean, string>[] = [
    [isInEnum, validatorRules.BR1_Mandatory_Field.FieldMessage],
];

const eotAddressValidator: ValidationSchema<Partial<AddressModel> | undefined> = (_values: Partial<AddressModel> | undefined) => {
    return {
        isAddressManual: isAddressManualValidator,
        addressLine1: addressLine1Validator,
        addressLine2: addressLine2Validator,
        localityName: suburbValidator,
        state: stateValidator,
        postcode: postcodeOptional,
    };
};

const eotAddressValidatorSoft: ValidationSchema<Partial<AddressModel> | undefined> = (_values: Partial<AddressModel> | undefined) => {
    return {
        isAddressManual: mandatoryValidator,
        addressLine1: mandatoryValidator,
        localityName: mandatoryValidator,
        state: mandatoryValidator,
        postcode: mandatoryValidator,
    };
};

const emailValidator: Tuple<(value: any | undefined) => boolean, string>[] = [
    [emailValidation, interpolateValidationString(validatorRules.BR10_Email_Format.FieldMessage, [['PropertyName', '{{label}}']], [])],
];

export const rnDEntityContactDetails: ValidationSchema<Partial<RnDEntityContactDetailsModel>> = (_values: Partial<RnDEntityContactDetailsModel>) => {
    return {
        eotContactTitleOther: titleOtherValidator,
        eotContactFirstName: namePersonValidator,
        eotContactLastName: namePersonValidator,
        eotContactPosition: positionValidator,
        eotContactPhone: phoneValidator,
        eotContactAddressLookup: eotAddressValidator,
        eotContactEmail: emailValidator,
    };
};

export const rnDEntityContactDetailsSoft: ValidationSchema<Partial<RnDEntityContactDetailsModel>> = (values: Partial<RnDEntityContactDetailsModel>) => {
    return {
        eotContactTitleOther: mandatoryString(() => values.eotContactTitle === 'Other'),
        eotContactFirstName: mandatoryValidator,
        eotContactLastName: mandatoryValidator,
        eotContactPosition: mandatoryValidator,
        eotContactPhone: mandatoryValidator,
        eotContactAddressLookup: eotAddressValidatorSoft,
        eotContactEmail: mandatoryValidator,
    };
};
