import { PageValidationFunctions } from '../types';
import { rnDEntityContactDetails, rnDEntityContactDetailsSoft } from './rnDEntityContactDetails';
import { delayReasonSoft, delayReason } from './delayReason';
import { declarationSubmissionSoft, declarationSubmission } from './declarationSubmission';

export const EOTRegistration: PageValidationFunctions = {
    rnDEntityContactDetails,
    rnDEntityContactDetailsSoft,
    delayReasonSoft,
    delayReason,
    declarationSubmissionSoft,
    declarationSubmission,
};
