import { WizardPage } from '../../components/wizard/types';
import { RnDEntityContactDetailsModel } from '../../api/models/eOTRegistration';
import { titleSelectInputProps } from '../CommonComponentProps';
import { FieldModel } from '../../components/form/pageForm/types';

const eotContactPageTextLabel: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'paragraph',
    label: 'Please note that all contacts listed in the application will receive correspondence about the application. Any contact listed may be contacted by the R&DTI Program to provide further information.',
    name: 'eotContactPageTextLabel',
};

const eotContactDetailsLabel: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'heading',
    label: 'Primary contact details:',
    name: 'eotContactDetailsLabel',
    componentProps: {
        linkField: 'eotContactTitle',
        tag: 'h3',
        className: 'rdtiH3',
    },
    contentKey: ['eotMainContact'],
};
const eotContactTitle = titleSelectInputProps('eotContactTitle', 'Title (optional)');
const eotContactTitleOther: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Other title',
    name: 'eotContactTitleOther',
    maxWidth: '10rem',
    maxLength: 30,
};
const eotContactFirstName: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'First name',
    name: 'eotContactFirstName',
    maxWidth: '22rem',
    maxLength: 50,
};
const eotContactLastName: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Last name',
    name: 'eotContactLastName',
    maxWidth: '22rem',
    maxLength: 50,
};
const eotContactPosition: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Position',
    name: 'eotContactPosition',
    maxWidth: '22rem',
    maxLength: 50,
};
const eotContactPhone: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Phone number',
    name: 'eotContactPhone',
    maxWidth: '16.25rem',
    contentKey: ['eotPhoneNumber'],
};
const eotContactEmail: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'textInput',
    label: 'Email',
    name: 'eotContactEmail',
    maxWidth: '22rem',
    maxLength: 100,
    contentKey: ['ContactDetailsEmail'],
};
const showChildErrorsForAddressLookup = (values: RnDEntityContactDetailsModel) => {
    if (values && values.eotContactAddressLookup && values.eotContactAddressLookup.isAddressManual) {
        return values.eotContactAddressLookup.isAddressManual === 'Yes';
    }

    return false;
};
const eotContactAddressLookup: FieldModel<RnDEntityContactDetailsModel> = {
    component: 'addressLookup',
    label: 'Main Business Address',
    name: 'eotContactAddressLookup',
    maxWidth: '33.5rem',
    componentProps: {
        noMatchText: 'No matches found. Enter the address manually.',
        showChildErrors: showChildErrorsForAddressLookup,
        fields: {
            addressLine1: {
                component: 'textInput',
                label: 'Address line 1',
                name: 'eotContactAddressLine1',
                maxWidth: '33.5rem',
            },
            addressLine2: {
                component: 'textInput',
                label: 'Address line 2',
                name: 'eotContactAddressLine2',
                maxWidth: '33.5rem',
            },
            localityName: {
                component: 'textInput',
                label: 'Suburb',
                name: 'eotContactAddressSuburb',
                maxWidth: '16.25rem',
            },
            state: {
                component: 'selectInput',
                label: 'State',
                name: 'eotContactAddressState',
                maxWidth: '9rem',
            },
            postcode: {
                component: 'textInput',
                label: 'Postcode',
                name: 'eotContactAddressPostcode',
                maxWidth: '9rem',
            },
        },
    },
    contentKey: ['eotMainBusinessAddress'],
    pdfFieldProps: {
        displayFlag: 'twoLines',
    },
};

const rnDEntityContactDetails: WizardPage<RnDEntityContactDetailsModel> = {
    apiEndpoint: '/contact-details',
    name: 'rnDEntityContactDetails',
    title: 'Contact details',
    fields: {
        eotContactPageTextLabel,
        eotContactDetailsLabel,
        eotContactTitle,
        eotContactTitleOther,
        eotContactFirstName,
        eotContactLastName,
        eotContactPosition,
        eotContactEmail,
        eotContactPhone,
        eotContactAddressLookup,
    },
    initialPageValues: {
        eotContactTitle: undefined,
        eotContactTitleOther: undefined,
        eotContactFirstName: undefined,
        eotContactLastName: undefined,
        eotContactPosition: undefined,
        eotContactEmail: undefined,
        eotContactPhone: undefined,
        eotContactAddressLookup: {
            isAddressManual: undefined,
            addressLine1: undefined,
            addressLine2: undefined,
            localityName: undefined,
            postcode: undefined,
            state: undefined,
        },
    },
};

export default rnDEntityContactDetails;
